import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MessageService, PrimeNGConfig } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { ImageModule } from "primeng/image";
import { MessagesModule } from "primeng/messages";
import { ToastModule } from "primeng/toast";
import { TabMenuModule } from "primeng/tabmenu";
import { AvatarModule } from "primeng/avatar";
import { AvatarGroupModule } from "primeng/avatargroup";
import { TieredMenuModule } from "primeng/tieredmenu";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { DialogModule } from "primeng/dialog";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { PaginatorModule } from "primeng/paginator";
import { SidebarModule } from "primeng/sidebar";
import { PanelMenuModule } from "primeng/panelmenu";
import { InputSwitchModule } from "primeng/inputswitch";
import { CardModule } from "primeng/card";
import { DividerModule } from "primeng/divider";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { TableModule } from "primeng/table";
import { DropdownModule } from "primeng/dropdown";
import { TabViewModule } from "primeng/tabview";
import { InputIconModule } from "primeng/inputicon";
import { IconFieldModule } from "primeng/iconfield";
import { CalendarModule } from "primeng/calendar";
import { FileUploadModule } from "primeng/fileupload";
import { FloatLabelModule } from "primeng/floatlabel";
import { InputTextareaModule } from "primeng/inputtextarea";
import { CheckboxModule } from "primeng/checkbox";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    ImageModule,
    MessagesModule,
    ProgressSpinnerModule,
    ToastModule,
    TabMenuModule,
    AvatarModule,
    AvatarGroupModule,
    TieredMenuModule,
    DialogModule,
    DynamicDialogModule,
    PaginatorModule,
    SidebarModule,
    PanelMenuModule,
    InputSwitchModule,
    CardModule,
    DividerModule,
    ConfirmDialogModule,
    FloatLabelModule,
    TabViewModule,
    CalendarModule,
    InputTextareaModule,
    CheckboxModule,
    TableModule,
    DropdownModule,
    InputIconModule,
    IconFieldModule,
    FileUploadModule,
    ButtonModule,
  ],
  exports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    ImageModule,
    MessagesModule,
    ProgressSpinnerModule,
    ToastModule,
    TabMenuModule,
    AvatarModule,
    AvatarGroupModule,
    TieredMenuModule,
    DialogModule,
    DynamicDialogModule,
    PaginatorModule,
    SidebarModule,
    PanelMenuModule,
    InputSwitchModule,
    CardModule,
    DividerModule,
    ConfirmDialogModule,
    FloatLabelModule,
    TabViewModule,
    CalendarModule,
    InputTextareaModule,
    TableModule,
    DropdownModule,
    InputIconModule,
    IconFieldModule,
    FileUploadModule,
    ButtonModule,
    CheckboxModule,
  ],
  providers: [PrimeNGConfig, MessageService],
})
export class PrimeNgModule {}
