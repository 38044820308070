import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { Theme } from '../../models/theme/theme.model';
import { AuthService } from '../../modules/auth/services/auth/auth.service';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';


const THEME_LOCAL_STORAGE_KEY = `portal_theme`;

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public themeChanged: BehaviorSubject<Theme> = new BehaviorSubject<Theme>(new Theme());
  public isLoadingChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly authService: AuthService,
    private httpClient: HttpClient) { }

  // * === === === === === === === === === === === === === ===
  // * PRIVATE METHODS
  // * === === === === === === === === === === === === === ===

  private getThemeFromLocalStorage(): Theme {
    const json = localStorage.getItem(THEME_LOCAL_STORAGE_KEY);
    if (!json)
      return new Theme();

    let theme = JSON.parse(json);
    return theme;
  }

  // * === === === === === === === === === === === === === ===
  // * PUBLIC METHODS
  // * === === === === === === === === === === === === === ===

  public checkIfThemeExists(theme: string): Observable<boolean> {
    let themeRoute = `${theme}.css`;
    return this.httpClient.get(themeRoute, { observe: 'response', responseType: 'blob' })
      .pipe(
        map(response => {
          return true;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }

  public loadTheme(): void {
    this.isLoadingChanged.next(true);
    const theme = this.getThemeFromLocalStorage();

    this.themeChanged.next(theme);
    if (theme.hash)
      this.isLoadingChanged.next(false);

    this.authService.getTheme(theme.hash).subscribe((theme: Theme) => {
      const previousTheme = this.getThemeFromLocalStorage();

      if (!theme.hasChanged) {
        this.isLoadingChanged.next(false);
        return;
      }

      if (!previousTheme || theme.hash !== previousTheme.hash) {
        localStorage.setItem(THEME_LOCAL_STORAGE_KEY, JSON.stringify(theme));
        this.themeChanged.next(theme);
      }
      this.isLoadingChanged.next(false);
    },
      () => {
        this.isLoadingChanged.next(false);
      }
    );
  }

  public switchTheme(theme: string): void {
    let themeLink = this.document.getElementById('app-style') as HTMLLinkElement;

    if (themeLink) {
      this.checkIfThemeExists(theme).subscribe(result => {
        if (result) {
          themeLink.href = `${theme}.css`;
          this.authService.setSelectedTheme(theme);
        } else {
          this.authService.setSelectedTheme('lara-light-blue');
        }
      })
    }
  }
}
