export class Theme {
    constructor(public header: any = {},
        public headerLogoImage: string = null,
        public subHeader: any = {},
        public background: any = {},
        public loginBackground: any = {},
        public loginButton: any = {},
        public loginLogoImage: string = null,
        public loginSideImage: string = null,
        public hash: string = '',
        public hasChanged: boolean = false) { }
}