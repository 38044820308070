import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaderConstants } from '../constants/http-header.constants';
import { AppConfigService } from '../_mx/services/appconfig.service';

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {

  constructor(private readonly configService: AppConfigService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const config = this.configService.getConfig();

    if (!config || !config.apiUrl || !request.url.startsWith(config.apiUrl)) return next.handle(request);

    const device = navigator.userAgent;

    let newReq = request.clone({
      headers: request.headers
        .append(HttpHeaderConstants.Device, device)
    });

    return next.handle(newReq);
  }
}
