import { Time } from "@angular/common";
import { BaseModel } from "src/app/_metronic/shared/crud-table/models/base.model";
import { ITime } from "../time.model";
import { ITimesheetRate } from "./timesheet-rates.model";

export interface ITimesheetDay extends BaseModel {
    id: any,
    mondayUnits : ITime,
    tuesdayUnits : ITime,
    wednesdayUnits : ITime,
    thursdayUnits : ITime,
    fridayUnits : ITime,
    saturdayUnits : ITime,
    sundayUnits : ITime,
    startDate : Date,
    endDate : Date,
    rate : ITimesheetRate,
}

export class TimesheetDay implements ITimesheetDay {
    constructor(
    public id : any = '',
    public mondayUnits : ITime = undefined,
    public tuesdayUnits : ITime = undefined,
    public wednesdayUnits : ITime = undefined,
    public thursdayUnits : ITime = undefined,
    public fridayUnits : ITime = undefined,
    public saturdayUnits : ITime = undefined,
    public sundayUnits : ITime = undefined,
    public startDate : Date = new Date(),
    public endDate : Date = new Date(),
    public rate : ITimesheetRate = undefined,
    ) {}
}