// Spain
export const locale = {
  lang: "es",
  "data": {
    "COMMON": {
      "CANCEL": "Cancelar",
      "SUBMIT": "Enviar",
      "NO": "No",
      "YES": "Sí",
      "YES_CLOSE_AND_LOSE_CHANGES": "Sí, cerrar y perder cambios",
      "DISCARD_AND_CLOSE": "Descartar y cerrar",
      "SAVE_AND_CLOSE": "Guardar y cerrar",
      "SEARCH": "Buscar...",
      "VALIDATION": {
        "SOMETHING_WENT_WRONG": "¡Vaya, parece que algo salió mal! Por favor, inténtalo de nuevo.",
        "SUCCESSFULLY_UPDATED": "Actualizado con éxito",
        "ANY_CHANGES_MADE_WILL_BE_LOST": "Cualquier cambio realizado se perderá.",
        "ARE_YOU_SURE_YOU_WANT_TO_PROCEED": "¿Estás seguro de que quieres continuar?",
        "FAILED_TO_RETRIEVE_DATA": "No se pudo recuperar datos del servidor",
        "FAILED_TO_RETRIEVE_DATA_FOR_SELECTION": "No se pudo recuperar datos para la selección",
      }
    },
    "NAVBAR": {
      "HELLO": "Hola",
      "MY_DETAILS": "Mis detalles",
      "MY_CONTACT_METHODS": "Mis métodos de contacto",
      "MY_DATA_CONSENTS": "Mis consentimientos de datos",
      "MY_DOCUMENTS": "Mis Documentos",
      "LOGOUT": "Cerrar sesión",
      "SELECT_LANGUAGE": "Seleccionar idioma",
      "MENU": "Menú",
      "CLOSE": "Cerrar",
      "SETTINGS": "Configuraciones",
      "TERMSANDCONDITIONS": "Términos y condiciones",
      "LANGUAGE": {
        "ENLABEL": "Inglés",
        "CHLABEL": "Chino",
        "ESLABEL": "Español",
        "JALABEL": "Japonés",
        "DELABEL": "Alemán",
        "FRLABEL": "Francés"
      },
      "MENU_ITEM": {
        "CURRENT_PLACEMENT": "Mi ubicación actual",
        "COMPLIANCE": "Mi cumplimiento",
        "TIMESHEETS": "Mis hojas de tiempo",
        "EXPENSES": "Mis gastos",
        "DOCUMENTS": "Mis Documentos",
      }
    },
    "AUTH": {
      "LOGIN": {
        "BUTTON": {
          "SIGN_IN": "Iniciar sesión"
        },
        "LABEL": {
          "EMAIL": "Correo electrónico",
          "PASSWORD": "Contraseña",
          "FORGOT_PASSWORD": "¿Olvidaste tu contraseña?"
        },
        "VALIDATION": {
          "EMAIL_INVALID": "Correo electrónico inválido",
          "EMAIL_MAX_LENGTH": "El correo electrónico no debe superar los {{value}} caracteres",
          "EMAIL_REQUIRED": "El correo electrónico es obligatorio",
          "LOGIN_FAILED": "Los detalles de inicio de sesión son incorrectos",
          "PASSWORD_MAX_LENGTH": "La contraseña no debe superar los {{value}} caracteres",
          "PASSWORD_MIN_LENGTH": "La contraseña debe tener al menos {{value}} caracteres",
          "PASSWORD_REQUIRED": "La contraseña es obligatoria"
        }
      },
      "FORGOT_PASSWORD": {
        "ENTER_YOUR_EMAIL_TO_RESET_YOUR_PASSWORD": "Introduce tu correo electrónico para restablecer tu contraseña",
        "FORGOTTEN_PASSWORD": "¿Contraseña olvidada?",
        "BUTTON": {
          "OK_GOT_IT": "¡Ok, entendido!"
        },
        "LABEL": {
          "EMAIL": "Correo electrónico",
          "EMAIL_SENT_MESSAGE1": "Hemos enviado un correo electrónico a la dirección que especificaste para restablecer tu contraseña.",
          "EMAIL_SENT_MESSAGE2": "Si no lo recibiste, por favor revisa tu carpeta de spam y verifica que la dirección de correo electrónico ingresada sea correcta.",
          "EMAIL_SENT_TITLE": "¡Correo de recuperación enviado!",
          "SUCCESS": "Tu cuenta ha sido restablecida con éxito."
        },
        "VALIDATION": {
          "EMAIL_INVALID": "Correo electrónico inválido",
          "EMAIL_MAX_LENGTH": "El correo electrónico no debe superar los {{value}} caracteres",
          "EMAIL_REQUIRED": "El correo electrónico es obligatorio"
        }
      },
      "RESET_PASSWORD": {
        "ENTER_NEW_PASSWORD_DETAILS": "Introduce los detalles de la nueva contraseña",
        "RESET_PASSWORD": "Restablecer contraseña",
        "BUTTON": {},
        "LABEL": {
          "CONFIRM_NEW_PASSWORD": "Confirmar nueva contraseña",
          "CREATE_NEW_PASSWORD": "Crear nueva contraseña",
          "OLD_PASSWORD": "Contraseña anterior",
          "PASSWORD": "Contraseña"
        },
        "VALIDATION": {
          "PASSWORDS_DONT_MATCH": "'Contraseña' y 'Confirmar contraseña' no coinciden",
          "PASSWORD_MAX_LENGTH": "La contraseña no debe superar los {{value}} caracteres",
          "PASSWORD_MIN_LENGTH": "La contraseña debe tener al menos {{value}} caracteres",
          "PASSWORD_PATTERN": "Debe contener letras mayúsculas y minúsculas, un número y uno de los siguientes: -+_!@#$%^&*.,?",
          "PASSWORD_REQUIRED": "La contraseña es obligatoria",
          "REGISTRATION_DETAILS_INCORRECT": "Los detalles de registro son incorrectos"
        }
      }
    },
    "MY_CONTACT_METHODS": {
      "MY_CONTACT_METHODS": "Mis métodos de contacto",
      "CONTACT_PREFERENCES": "Preferencias de contacto",
      "PLEASE_SELECT_HOW_YOU_WISH_TO_BE_CONTACTED": "Por favor, selecciona cómo deseas ser contactado.",
      "BUTTON": {},
      "LABEL": {
        "RECEIVE": "Recibir",
        "DO_NOT_RECEIVE": "No recibir",
        "SALES_EMAIL": "Correo electrónico de ventas",
        "EMAIL": "Correo electrónico",
        "PHONE_CALL": "Llamada telefónica",
        "TEXT_MESSAGE": "Mensaje de texto"
      },
      "VALIDATION": {
        "ERROR_UPDATING": "Hubo un error al actualizar las preferencias de contacto"
      }
    },
    "MY_DETAILS": {
    "MY_DETAILS": "Mis Detalles",
    "TAB_TITLES": {
      "CANDIDATE_INFORMATION": "Información del Candidato",
      "PREFERENCES": "Preferencias",
      "PROFILE": "Perfil"
    },
    "CANDIDATE_INFORMATION_TAB": {
      "FIRST_NAME": "Nombre",
      "LAST_NAME": "Apellido",
      "MOBILE_PHONE": "Teléfono Móvil",
      "HOME_PHONE": "Teléfono de Casa",
      "WORK_PHONE": "Teléfono del Trabajo",
      "PERSONAL_EMAIL": "Correo Electrónico Personal",
      "WORK_EMAIL": "Correo Electrónico del Trabajo",
      "LINKEDIN": "LinkedIn",
      "ADDRESSLINE1": "Línea de Dirección 1",
      "CITY_TOWN": "Ciudad/Pueblo",
      "POSTCODE": "Código Postal",
      "COUNTRY": "País"
    },
    "PREFERENCES_TAB": {
      "AVAILABILITY": "Disponibilidad",
      "NOTICE_PERIOD": "Periodo de Preaviso",
      "CURRENCY": "Moneda",
      "CONTRACTOR": "Contratista",
      "TEMP": "Temporal",
      "RATE_FROM": "Tarifa Desde",
      "PAY_FREQUENCY": "Frecuencia de Pago",
      "RATE_TO": "Tarifa Hasta",
      "PERMANENT": "Permanente",
      "SALARY_FROM": "Salario Desde",
      "SALARY_TO": "Salario Hasta",
      "CHOOSE_INTERVAL": "Elegir intervalo...",
      "SELECT_A_CURRENCY": "Seleccionar una moneda...",
      "PAY_FREQUENCY_PLACEHOLDER": "Seleccionar una frecuencia de pago...",
    },
    "PROFILE_TAB": {
      "CANDIDATE_SUMMARY": "Resumen del Candidato",
      "ROLES_SOUGHT": "Roles Buscados",
      "CANDIDATE_SUMMARY_PLACEHOLDER": "Por favor, introduzca un resumen del candidato...",
      "ROLESSOUGHT_SUMMARY_PLACEHOLDER": "Por favor, introduzca un resumen del rol buscado por el candidato..."
    },
    "VALIDATION": {
    "ERROR_UPDATING": "Se produjo un error al actualizar sus detalles",
    "FIRST_NAME_VALIDATION": "El nombre es un campo obligatorio",
    "LAST_NAME_VALIDATION": "El apellido es un campo obligatorio",
    "PHONE_NUMBER_VALIDATION": "Por favor, introduzca un número de teléfono válido",
    "PHONE_NUMBER_REQUIRED": "Se requiere teléfono móvil",
    "EMAIL_VALIDATION": "Por favor, introduzca una dirección de correo electrónico válida",
    "EMAIL_REQUIRED": "Se requiere el correo electrónico personal",
    "FREQUENCY_REQUIRED": "Se requiere la frecuencia",
    "RATE_FROM_VALIDATION": "Asegúrese de que la 'Tasa máxima' sea mayor o igual a la 'Tasa mínima'",
    "CANDIDATE_SUMMARY_VALIDATION": "El máximo de caracteres permitidos es 5,000",
    "ROLE_SOUGHT_VALIDATION": "El máximo de caracteres permitidos es 2,000",
    "SALARY_TO_VALIDATION": "Asegúrese de que el 'Salario máximo' sea mayor o igual al 'Salario mínimo'",
    "SALARY_REQUIRED": "Salario requerido",
    "PAY_FREQUENCY_REQUIRED": "Frecuencia de pago requerida",
    "SELECT_A_UNIT": "Por favor seleccione una unidad",
    "RATE_SET_REQUIRED": "Requerido si se establece una tarifa",
    "SALARY_SET_REQUIRED": "Requerido si se establece un salario",
    "NOTICE_PERIOD_VALIDATION": "El período de preaviso no debe ser inferior a 0"
  }
    },
    "MY_DATA_CONSENTS": {
      "MY_DATA_CONSENTS": "Mis Consentimientos de Datos",
      "PLEASE_SELECT_HOW_YOU_WISH_TO_MANAGE_YOUR_DATA_CONSENTS": "Por favor seleccione cómo desea gestionar sus consentimientos de datos.",
      "BUTTON": {},
      "LABEL": {
        "CONSENT": "Consentimiento",
        "WITHDRAW": "Retirar",
        "DATA_CONSENT_PURPOSE": "Propósito del Consentimiento de Datos",
        "CONSENT_METHOD": "Método de Consentimiento",
        "CONSENT_GIVEN": "Consentimiento Otorgado",
        "CONSENT_EXPIRY": "Vencimiento del Consentimiento",
        "WITHDRAW_CONSENT": "Retirar Consentimiento"
      },
      "VALIDATION": {
        "ERROR_UPDATING": "Hubo un error al actualizar mis consentimientos de datos"
      }
  },
  "MY_CURRENT_PLACEMENT": {
    "MY_CURRENT_PLACEMENTS": "Mis colocaciones actuales",
    "BUTTON": {},
    "LABEL": {
      "NO_ACTIVE_PLACEMENTS": "No hay colocaciones activas",
      "CLIENT": "Cliente",
      "PLACEMENT_DETAILS": "Detalles de la colocación",
      "JOB_TITLE": "Título del trabajo",
      "TYPE": "Tipo",
      "START_DATE": "Fecha de inicio",
      "END_DATE": "Fecha de finalización",
      "CANDIDATE_COMPANY": "Empresa del candidato",
      "NOTICE_FROM_US": "Aviso de nuestra parte",
      "NOTICE_FROM_YOU": "Aviso de su parte",
      "PAY": "Pago",
      "CLIENT_ADDRESS": "Dirección del cliente",
      "COMPLIANCE": "Cumplimiento",
      "STATUS": "Estado",
      "EVIDENCE": "Evidencia",
      "REASON_FOR_REJECTION": "Razón de la rechazo",
      "VALID_FROM": "Válido desde",
      "VALID_TO": "Válido hasta",
      "DESCRIPTION": "Descripción",
      "LAST_UPDATED": "Última actualización",
      "DOCUMENT": "Documento",
      "PLEASE_CLICK_TO_DOWNLOAD": "Haga clic para descargar",
      "DOWNLOAD": "Descargar",
      "NO_DOCUMENTS_FOUND": "No se encontraron documentos",
      "CHECKED_ON": "Revisado el",
      "CHECKED_BY": "Revisado por",
      "OUTSTANDING": "Pendiente",
      "SUBMITTED": "Enviado",
      "CHECKED": "Verificado",
      "REJECTED": "Rechazado",
    },
    "VALIDATION": {
      "FROM_DATE_IS_REQUIRED": "La fecha de inicio es obligatoria",
      "TO_DATE_IS_REQUIRED": "La fecha de finalización es obligatoria",
      "TO_DATE_LESS_THAN_FROM_DATE": "La fecha de finalización debe ser posterior a la fecha de inicio",
      "FROM_DATE_GREATER_THAN_TO_DATE": "La fecha de inicio no puede ser posterior a la fecha de finalización",
      "ERROR_SAVING": "Error al guardar el cumplimiento",
      "TO_DATE_MUST_BE_IN_FUTURE": "La fecha de finalización debe establecerse en una fecha futura"
    }
  },
  "FILEUPLOAD": {
    "CHOOSEFILE": "Elegir archivo",
    "UPLOADFILE": "Subir archivo",
    "REMOVEFILE": "Eliminar archivo",
    "PENDINGUPLOAD": "Carga pendiente",
    "COMPLETEDUPLOAD": "Carga completada",
    "COMPLETED": "Completado",
    "VALIDATION": {
      "INVALID_FILE": "Archivo seleccionado no válido",
      "FILE_IS_ZERO_SIZE": "El tamaño del archivo no puede ser cero. Por favor, seleccione un archivo válido y vuelva a intentarlo.",
      "MULTIPLE_FILES_ARE_ZERO_SIZE": "Los tamaños de los archivos no pueden ser cero. Los archivos no válidos serán eliminados. Asegúrese de que todos los archivos seleccionados sean válidos y vuelva a intentarlo."
    }
  }
  }
};
