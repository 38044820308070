import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { IAppConfig } from '../models/appconfig.model';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
    constructor(private http: HttpClient) {
    }
    
    private appConfig: IAppConfig;

    load() {
       return this.http.get('assets/_mx/config/config.json')
        .toPromise()
        .then(response =>{
            this.appConfig = <IAppConfig>response;
            sessionStorage.setItem("config", JSON.stringify(this.appConfig));
        });

    }

     getConfig() {
          if (this.appConfig == null)
             this.appConfig = JSON.parse(sessionStorage.getItem("config"));

         return this.appConfig;
    }
}