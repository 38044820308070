import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { IAppConfig } from "../models/appconfig.model";


@Injectable({
    providedIn: 'root',
})
export class AppConfigService {
    private appConfig: IAppConfig;

    constructor(private http: HttpClient) {
    }

    load() {
        return this.http.get('assets/_primeng/config/config.json')
            .toPromise()
            .then(response => {
                this.appConfig = <IAppConfig>response;
                sessionStorage.setItem("config", JSON.stringify(this.appConfig));
            });
    }

    getConfig() {
        if (this.appConfig == null)
            this.appConfig = JSON.parse(sessionStorage.getItem("config"));

        return this.appConfig;
    }
}