import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MercuryLoaderComponent } from "src/app/_primeng/shared/mercury-loader/mercury-loader.component";
import { FileUploaderComponent } from "../../shared/file-uploader/file-uploader.component";
import { PrimeNgModule } from "src/app/modules/primeng/primeng.module";
import { TranslationModule } from "src/app/modules/i18n/translation.module";

@NgModule({
  declarations: [MercuryLoaderComponent, FileUploaderComponent],
  imports: [CommonModule, PrimeNgModule, TranslationModule],
  exports: [MercuryLoaderComponent, FileUploaderComponent],
})
export class SharedModule {}
